import React, { createContext, useContext, useReducer } from 'react'

const LOCAL_STORAGE_NAME = "layout_1.3"

export const TOGGLE_RENDER_SVG = "TOGGLE_RENDER_SVG"
export const RENDER_SVG = "RENDER_SVG"
export const WINDOW_WIDTH = "WINDOW_WIDTH"
export const UPDATE_LAYOUT = "UPDATE_LAYOUT"
export const DISPATCH_RESET_LAYOUT = "RESET_LAYOUT"

export const LayoutContext = createContext();

export const SHOW_ADS = false;

// standard layout margin
export const MARGIN = 10;
export const MAX_WIDTH = SHOW_ADS ? 1600: 1200;
// export const ADSPACE = 200;


export const InitialLayout = {
    renderSVG: false,
    windowWidth: 0,
    contentWidth:0,

    expandInput_offset: false,
    expandInput_extraPayments: false,
    expandInput_lumpSum: false,

    expandInput_costs:false,
    expandInput_repayments:false,
    expandInput_investments:false,
}


export const readLayout = () => {
    let data = InitialLayout;
    if (typeof (Storage) !== "undefined") {
        try {
            data = JSON.parse(localStorage.getItem(LOCAL_STORAGE_NAME));

            const localData = localStorage.getItem(LOCAL_STORAGE_NAME);
            if (localData) {
                data = JSON.parse(localData);
            }
        }
        catch (error) { console.log(error) }

        data = { ...InitialLayout, ...data };
    }

    return data
}


export const saveLayout = (layoutData) => {
    // only save the theme type not the entire object
    if (typeof (Storage) !== "undefined") {
        return localStorage.setItem(LOCAL_STORAGE_NAME, JSON.stringify(layoutData));
    }
    return;
}


// Reducer ations
export const layoutReducer = (state, action) => {
    let newState;
    switch (action.type) {
        case DISPATCH_RESET_LAYOUT:
            newState = { ...state, 
                expandInput_offset: InitialLayout.expandInput_offset,
                expandInput_extraPayments: InitialLayout.expandInput_extraPayments,
                expandInput_lumpSum: InitialLayout.expandInput_lumpSum,
                expandInput_costs: InitialLayout.expandInput_costs,
                expandInput_repayments: InitialLayout.expandInput_repayments,
                expandInput_investments: InitialLayout.expandInput_investments,
            };
            saveLayout(newState);
            return newState;
        case RENDER_SVG:
            newState = { ...state, renderSVG: true }
            return newState;
        
            case UPDATE_LAYOUT:
                newState = { ...state, ...action.data };
                saveLayout(newState);
                return newState;

        case WINDOW_WIDTH:
            let adOffset = action.data.offset
            newState = { ...state, windowWidth: action.data.width - adOffset, contentWidth: Math.min(MAX_WIDTH-adOffset, action.data.width - adOffset), maxWidth: MAX_WIDTH-adOffset }
            return newState;
        default:
            return state;
    }
};


// Wrapper for the Provider injection
export const LayoutProvider = ({ initialState, children }) => {
    return (
        <LayoutContext.Provider value={useReducer(layoutReducer, initialState)}>
            {children}
        </LayoutContext.Provider>
    )
};

// NOTE: A component calling useContext will always re-render when the context value changes. If re-rendering the component is expensive, you can optimize it by using memoization.
export const useLayout = () => useContext(LayoutContext);
