import { InitialState, VACANT_LAND } from './calculatorConstants'
import { calculateBracket, matchConditions } from './CalculatorUtils';
import { PMT } from './CalculatorExcelFunctions';
import { roundCents, sumArray } from '../utils/utils'
import { spline } from '../utils/svgUtils'
import { data } from './CalculatorData';
import Raphael from 'raphael'

//-----------------------------------------------------
// Constants
//-----------------------------------------------------

const state = () => Calculator.state;


//-----------------------------------------------------
// Calculator
//-----------------------------------------------------Raphael
export const Calculator = {
    state: {},

    setState: (obj) => {
        Calculator.state = obj;
        return Calculator;
    },

    isDebug: false,


    ///////////////////////////////////////////
    // RESET
    ///////////////////////////////////////////
    reset: () => {
        state().lmi = [];
        state().conveyancing = [];
        state().lvr = 0;
        state().stampduty = 0;
        state().fees = [];
        state().feesAnnual = [];
        state().concessions = [];
        state().totalCosts = 0;

        state().loanValue_data = [];
        state().loanRemaining_data = [];
        state().loanPrincipal_data = [];
        state().loanInterest_data = [];
        state().propertyValue_data = [];
        state().propertyValueChange_data = [];
        state().monthlyPayments_data = [];
        state().monthlyPaymentsRelative_data = [];
        state().equity_data = [];
        state().propertyValueRelative_data = [];
        state().equityRelative_data = [];

        state().offsetPayout.amount = 0
        state().offsetPayout.date = 0

        return Calculator;
    },


    validate: () => {
        if (state().propertyValue < 0) state().propertyValue = Math.abs(state().propertyValue);
        if (state().loanAmount < 0) state().loanAmount = Math.abs(Number(state().loanAmount));
        if (state().term === undefined || !state().term || state().term <= 0) state().term = 1;

        if (state().buildingType === VACANT_LAND) {
            state().landValue = Math.min(state().propertyValue, state().landValue) // reset the land value to the property value
        } else {
            state().landValue = state().propertyValue // reset the land value to the property value
        }
        if (Number(state().interestRate) > 100) state().interestRate = 100;

        return Calculator;
    },

    // if the term updates, force any term limits to conform to this limit
    checkLimits: () => {

        let term = Number(state().mortgagePrimary.term);
        if (state().splitLoan) {
            term = Math.max(term, Number(state().mortgageSecondary.term))
        }

        // max term of any loan
        state().term = term;

        // Offset and repayments
        // make sure term from and to values are valid (if term changes)
        let payment;
        for (let i = 0; i < state().offsetAccountTransactions.length; i++) {
            payment = state().offsetAccountTransactions[i];
            if (payment.from > term) payment.from = term;
            if (payment.to > term) payment.to = term;
        }

        for (let i = 0; i < state().additionalPayments.length; i++) {
            payment = state().additionalPayments[i];
            if (payment.from > term) payment.from = term;
            if (payment.to > term) payment.to = term;
        }

        for (let i = 0; i < state().lumpSumPayments.length; i++) {
            payment = state().lumpSumPayments[i];
            if (payment.month > term) payment.month = term;
        }

        return Calculator;
    },


    // Simply calibrates the trend figures so the fit neatly into the predefined trend control points
    // calculateInterestRatesForecast interpolates the rates throughout the life of the loan
    calculateInterestRateTrends: () => {

        const term = state().term;
        let startDate = new Date(state().startDate)
        let from = startDate.getFullYear() + startDate.getMonth() / 12
        let to = new Date(state().startDate);
        to = new Date(to.setMonth(to.getMonth() + term));
        to = to.getFullYear() + to.getMonth() / 12

        // ----------------  INTEREST RATES ---------------------------------------
        // determine the interest rate from inputs (interprative)

        let variablePrimary = state().mortgagePrimary.initialPeriod.data === 1
        let variableSecondary = state().mortgageSecondary.initialPeriod.data === 1



        // Determin the interest rate based on inputs
        if (state().splitLoan) {
            if ((variablePrimary && variableSecondary) || (!variablePrimary && !variableSecondary)) {
                // default to first mortgage as the default rate
                state().interestRate = Number(state().mortgagePrimary.interestRate)
                //state().interestRate = 0.5 * (Number(state().mortgagePrimary.interestRate) + Number(state().mortgageSecondary.interestRate))
            }
            if (variablePrimary && !variableSecondary) state().interestRate = Number(state().mortgagePrimary.interestRate)
            if (!variablePrimary && variableSecondary) state().interestRate = Number(state().mortgageSecondary.interestRate)
        } else {
            state().interestRate = Number(state().mortgagePrimary.interestRate)
        }

        if (!state().interestRateForecast) state().interestRateForecast = Number(state().mortgagePrimary.interestRate)


        const interestRate = Number(state().interestRate); // start
        const interestRateForecast = Number(state().interestRateForecast) // end
        let interstRateTrend = state().interestRateTrend; // points in between


        // make sure trend points are still within the term period
        if (interstRateTrend[0][0] > to || interstRateTrend[1][0] > to) {
            // reset the dates
            interstRateTrend[0][0] = from + term / 12 * 0.1
            interstRateTrend[1][0] = from + term / 12 * 0.5
        }


        // trim dates
        if (interstRateTrend.length !== 2) {
            // RESET INTEREST RATE TREND
            interstRateTrend = [[
                from + term / 12 * 0.1,
                interestRate + (0.5 * (interestRateForecast - interestRate))
            ],
            [
                from + term / 12 * 0.5,
                interestRate + (0.9 * (interestRateForecast - interestRate))
            ]
            ];
        }

        // RESET DATES
        if (!state().customInterestRates) {
            interstRateTrend[0][0] = from + term / 12 * 0.1;
            interstRateTrend[1][0] = from + term / 12 * 0.5;
        }

        state().interestRateTrend = interstRateTrend;
        return Calculator;
    },


    calculatePropertyGrowthTrends: () => {
        const term = Number(state().term)
        let startDate = new Date(state().startDate)
        let from = startDate.getFullYear() + startDate.getMonth() / 12
        let to = new Date(state().startDate);
        to = new Date(to.setMonth(to.getMonth() + term));
        to = to.getFullYear() + to.getMonth() / 12


        // ----------------  PROPERTY GROWTH ---------------------------------------
        const propertyGrowth = Number(state().propertyGrowth);
        const propertyGrowthForecast = Number(state().propertyGrowthForecast);
        let newPropertyGrowthTrend = [];

        // trim point that are out of range
        state().propertyGrowthTrend.map(value => {
            if (value[0] > from && value[0] < to) newPropertyGrowthTrend.push(value);
            return false
        })

        // trim dates
        if (newPropertyGrowthTrend.length !== 2) {
            // RESET INTEREST RATE TREND
            newPropertyGrowthTrend = [[
                from + term / 12 * 0.2,
                propertyGrowth + (0.5 * (propertyGrowthForecast - propertyGrowth))
            ],
            [
                from + term / 12 * 0.5,
                propertyGrowth + (0.9 * (propertyGrowthForecast - propertyGrowth))
            ]
            ];
        }

        state().propertyGrowthTrend = newPropertyGrowthTrend;
        return Calculator;
    },


    calculateDeposit: () => {
        let d = Math.max(0, Number(state().propertyValue) - Number(state().loanAmount));
        if (state().deposit !== d) {
            state().deposit = d;
        }
        return Calculator;
    },

    calculateLVR: () => {
        let lvr = Number(state().loanAmount) / Number(state().propertyValue);
        if (isNaN(lvr)) state().lvr = lvr = 0;
        if (state().lvr !== lvr) {
            state().lvr = lvr
        }
        return Calculator;
    },

    calculateLVRTarget: () => {

        // for each loan amount and property value, check if the LVR is >= lvrTarget

        let repaymentCount = state().propertyValue_data.length;
        let checkMonthIndex = 0;
        let target = state().lvrTarget * 0.01;
        while (checkMonthIndex < repaymentCount) {
            let eq = state().equity_data[checkMonthIndex];
            let pv = state().propertyValue_data[checkMonthIndex];
            let lvr = (pv - eq) / pv;
            if (lvr <= target) break;
            checkMonthIndex++;
        }


        let lvrTargetDate = new Date(state().startDate);
        lvrTargetDate = new Date(lvrTargetDate.setMonth(lvrTargetDate.getMonth() + checkMonthIndex));
        state().lvrTargetDate = lvrTargetDate;
        state().lvrTargetMonth = checkMonthIndex;

        return Calculator;
    },

    calculateLMI: () => {

        const propertyValue = Number(state().propertyValue);
        const { lmi } = data;
        const { rates, threshold } = lmi;
        const lvr = state().lvr * 100;
        const stateTerritory = state().stateTerritory;

        if (lvr <= threshold) return Calculator;

        for (let i = 0; i < rates.length; i++) {
            if (lvr < rates[i].lvr) {
                // ES5 friendly
                const d = rates[i].rates.filter((item) => item.state.toUpperCase() === stateTerritory.toUpperCase()).shift();
                const low = propertyValue * d.low / 100;
                const high = propertyValue * d.high / 100
                state().lmi = [low, 0.5 * (low + high), high];
                return Calculator;
            }
        }

        return Calculator;
    },


    calculateConveyancing: () => {

        const { conveyancing } = data;
        const { rates } = conveyancing;
        let stateTerritory = state().stateTerritory;
        if (!stateTerritory) {
            stateTerritory = InitialState.stateTerritory;
            state().stateTerritory = stateTerritory;
        }

        const d = rates.filter((item) => item.state.toUpperCase() === stateTerritory.toUpperCase()).shift();
        if (!d) return Calculator;

        // low, average, high
        state().conveyancing = [d.low, d.low + 0.5 * (d.high - d.low), d.high];
        return Calculator;
    },




    calculatePropertyGrowthForecast: () => {
        const startDate = new Date(state().startDate);
        // pre calculate the trend of the rates based on the forecast
        const term = Number(state().term);
        const propertyGrowth = Number(state().propertyGrowth)
        const propertyGrowthForecast = Number(state().propertyGrowthForecast)
        const startYear = new Date(startDate).getFullYear() + new Date(startDate).getMonth() / 12

        let lineData, path, splinePoints, i;

        // -----------------------------------------------
        // PropertyGrowth per month
        // -----------------------------------------------
        let growthPerMonth = [];
        let growthPerYear = [];

        const propertyGrowthTrend = state().propertyGrowthTrend ? state().propertyGrowthTrend : [];

        splinePoints = [{ x: 0, y: propertyGrowth }]

        for (i = 0; i < propertyGrowthTrend.length; i++) {
            splinePoints.push({
                x: propertyGrowthTrend[i][0] - startYear,
                y: propertyGrowthTrend[i][1]
            })
        }
        splinePoints.push({ x: term / 12, y: Number(state().propertyGrowthForecast) })
        lineData = spline(splinePoints);

        path = new Raphael(0, 0, 1, 1).path(lineData);
        let len = path.getTotalLength();
        let totalSteps = term * 3;
        let pathStep = len / totalSteps;
        let targetYear = 0;
        let targetMonth = 0;
        let lastValidPoint = null
        let tx, ty

        growthPerMonth.push(propertyGrowth)
        //walk along spline, step every month
        for (i = 0; i < totalSteps; i++) {

            let convertPointToPath = path.getPointAtLength(i * pathStep);
            if (!lastValidPoint) lastValidPoint = convertPointToPath;
            tx = convertPointToPath.x;
            ty = convertPointToPath.y;

            // is this point in a new month?
            if (tx >= targetMonth) {
                // update point
                lastValidPoint = convertPointToPath;
                // save month
                growthPerMonth.push(ty)
                // increment to next month path position
                targetMonth += 1 / 12

                //Year
                if (tx >= targetYear) {
                    // save month
                    growthPerYear.push(ty)
                    // increment to next month
                    targetYear += 1;
                }
            }
        }

        // fill forward
        while (growthPerMonth.length <= term) {
            growthPerMonth.push(propertyGrowthForecast)
        }
        //growthPerMonth[term + 1] = propertyGrowthForecast

        state().growthPerMonth = growthPerMonth;
        state().growthPerYear = growthPerYear;

        return Calculator;
    },


    // Calculate rates for each year of the loan
    calculateInterestRatesForecast: () => {

        // Primary Mortgage
        const primary = Calculator.calculateInterestRatesForecast_split(state().mortgagePrimary)
        state().mortgagePrimary.interestRatesPerMonth = primary.interestRatesPerMonth
        state().mortgagePrimary.interestRatesPerYear = primary.interestRatesPerYear

        state().interestRatesPerMonth = primary.interestRatesPerMonth;
        state().interestRatesPerYear = primary.interestRatesPerYear;

        if (state().splitLoan) {
            // Secondary Mortgage
            const secondary = Calculator.calculateInterestRatesForecast_split(state().mortgageSecondary)
            state().mortgageSecondary.interestRatesPerMonth = secondary.interestRatesPerMonth
            state().mortgageSecondary.interestRatesPerYear = secondary.interestRatesPerYear
        }

        return Calculator;
    },


    calculateInterestRatesForecast_split: (mortgage) => {
        const startDate = new Date(state().startDate);
        // pre calculate the trend of the rates based on the forecast
        const customInterestRates = state().customInterestRates;
        const term = Number(mortgage.term);
        const interestRate = Number(mortgage.interestRate)
        const defaultInterestRate = Number(state().interestRate)
        const interestRateTrend = state().interestRateTrend
        const interestRateForecast = customInterestRates ? state().interestRateForecast : interestRate
        // const startYear = new Date(startDate).getFullYear()
        const startYear = new Date(startDate).getFullYear() + new Date(startDate).getMonth() / 12
        let initialPeriod = mortgage.initialPeriod.data;
        if (initialPeriod === 0) initialPeriod = term; // use fixed full term 
        if (initialPeriod < 0) initialPeriod = 0; // Interest only, default to variable
        let refinancePeriod = state().refinancePeriod.data;

        let lineData, path, splinePoints, i, tx, ty = 0

        // -----------------------------------------------
        // InterestRates
        // -----------------------------------------------
        let interestRatesPerYear = [];
        let interestRatesPerMonth = [];

        if (customInterestRates) {

            // inital year
            splinePoints = [{ x: 0, y: interestRate }]
            // inbetween years
            for (i = 0; i < interestRateTrend.length; i++) {
                splinePoints.push({
                    x: interestRateTrend[i][0] - startYear,
                    y: interestRateTrend[i][1]
                })
            }

            // end year
            splinePoints.push({ x: term / 12, y: interestRateForecast })

            lineData = spline(splinePoints);
            path = new Raphael(0, 0, 1, 1).path(lineData);

            let len = path.getTotalLength();
            let totalSteps = term * 20; // over sample line by 3
            let pathStep = len / totalSteps;
            let targetYear = 0;
            let targetMonth = 0;
            let lastValidPoint = null

            interestRatesPerMonth = [];
            interestRatesPerMonth.push(interestRate)

            let endPoint = path.getPointAtLength(len).x
            const monthStride = (endPoint) / term;

            // walk along path checking each point until the x component has stepped into a new month/year
            for (i = 0; i <= totalSteps; i++) {


                let convertPointToPath = path.getPointAtLength(i * pathStep);
                if (!lastValidPoint) lastValidPoint = convertPointToPath;
                tx = convertPointToPath.x;
                ty = convertPointToPath.y;
                // is this point in a new month?
                if (tx >= targetMonth) {
                    let monthIndex = Math.round(targetMonth * 12);
                    let refinanceIndex = monthIndex % refinancePeriod
                    // New Month
                    if (initialPeriod * 12 > 0) {
                        //hold at zero
                        tx = 0;
                        ty = lastValidPoint.y
                        initialPeriod--;
                    } else if (refinanceIndex !== 0) {
                        // hold at last point
                        ty = lastValidPoint.y
                    } else {
                        // update point
                        lastValidPoint = convertPointToPath;
                    }

                    // save month
                    if (interestRatesPerMonth.length < term) {
                        interestRatesPerMonth.push(ty)
                    }
                    // increment to next month path position
                    targetMonth += monthStride

                    //Year
                    if (tx >= targetYear) {
                        // save month
                        interestRatesPerYear.push(ty)
                        // increment to next month
                        targetYear += 1;
                    }
                }
            }
            // fill forward
            while (interestRatesPerMonth.length <= term) {
                interestRatesPerMonth.push(interestRateForecast)
            }
        } else {
            // no need to calculate changes in interest rates - no curves
            interestRatesPerMonth.push(interestRate)
            for (i = 0; i <= term; i++) {
                if (i < initialPeriod || initialPeriod === 1) {
                    interestRatesPerMonth.push(interestRate)
                } else {
                    interestRatesPerMonth.push(defaultInterestRate)
                }
            }
        }
        return { interestRatesPerMonth, interestRatesPerYear };
    },



    calculateLoanSplit: () => {
        // manually update mortgage details
        if (state().splitLoan) {
            state().mortgagePrimary.ratio = 1 - state().splitLoanRatio;
            state().mortgagePrimary.loanAmount = state().loanAmount * state().mortgagePrimary.ratio
            state().mortgageSecondary.ratio = state().splitLoanRatio;
            state().mortgageSecondary.loanAmount = state().loanAmount * state().mortgageSecondary.ratio

        } else {
            // set loan 1 to be the full loan value, second to be zero
            state().mortgagePrimary.ratio = 1;
            state().mortgagePrimary.loanAmount = state().loanAmount;
            state().mortgageSecondary.ratio = 0
            state().mortgageSecondary.loanAmount = 0
        }

        return Calculator;
    },

    calculateMortgage: () => {

        state().mortgagePrimary = Calculator.calculateMortgage_split(state().mortgagePrimary)

        if (state().splitLoan) state().mortgageSecondary = Calculator.calculateMortgage_split(state().mortgageSecondary)

        if (state().splitLoan) {
            // combined values
            state().monthlyPayments_data = state().mortgagePrimary.monthlyPayments_data.map((v, i) => v + state().mortgageSecondary.monthlyPayments_data[i])
            state().loanValue_data = state().mortgagePrimary.loanValue_data.map((v, i) => v + state().mortgageSecondary.loanValue_data[i])
            state().loanInterest_data = state().mortgagePrimary.loanInterest_data.map((v, i) => v + state().mortgageSecondary.loanInterest_data[i])
            state().loanPrincipal_data = state().mortgagePrimary.loanPrincipal_data.map((v, i) => v + state().mortgageSecondary.loanPrincipal_data[i])
            state().loanRemaining_data = state().mortgagePrimary.loanRemaining_data.map((v, i) => v + state().mortgageSecondary.loanRemaining_data[i])
        } else {
            // Primary Mortgage only
            state().monthlyPayments_data = state().mortgagePrimary.monthlyPayments_data.map(v => v)
            state().loanValue_data = state().mortgagePrimary.loanValue_data.map(v => v)
            state().loanInterest_data = state().mortgagePrimary.loanInterest_data.map(v => v)
            state().loanPrincipal_data = state().mortgagePrimary.loanPrincipal_data.map(v => v)
            state().loanRemaining_data = state().mortgagePrimary.loanRemaining_data.map(v => v)
        }

        return Calculator;
    },


    calculateMortgage_split: (mortgage) => {



        let remainingPayments = Number(mortgage.term);
        let loanAmount = Number(mortgage.loanAmount);
        let loanAmountOutstanding = loanAmount;

        // initialise to start of loan
        // const monthlyPayments_data = [0];
        // const loanValue_data = [loanAmount];
        // const loanInterest_data = [0];
        // const loanPrincipal_data = [0];
        // const loanRemaining_data = [loanAmount];
        const monthlyPayments_data = [];
        const loanValue_data = [];
        const loanInterest_data = [];
        const loanPrincipal_data = [];
        const loanRemaining_data = [];

        const interestOnly = mortgage.initialPeriod.data < 0

        for (let m = 0; m < mortgage.term; m++) {
            let monthlyInterestRate, monthly;
            monthlyInterestRate = mortgage.interestRatesPerMonth[m] / (12 * 100);
            monthly = interestOnly ?
                monthlyInterestRate * loanAmountOutstanding :
                PMT(monthlyInterestRate, remainingPayments, -1 * loanAmountOutstanding);

            let interest = loanAmountOutstanding * monthlyInterestRate;
            let principal = interestOnly ? 0 : monthly - interest;

            monthlyPayments_data.push(monthly);
            loanValue_data.push(loanAmountOutstanding);
            loanInterest_data.push(interest)
            loanPrincipal_data.push(principal);

            // to nearest cent
            loanRemaining_data.push(Math.abs(roundCents(loanAmountOutstanding)));
            loanAmountOutstanding -= principal;
            remainingPayments--;

            //}
        }

        // any interest only loans need to extend the length of the full term
        if (state().term > mortgage.term) {
            for (let m = mortgage.term; m < state().term; m++) {
                if (interestOnly) {
                    monthlyPayments_data.push(0);
                    loanValue_data.push(loanAmount);
                    loanInterest_data.push(0)
                    loanPrincipal_data.push(0);
                    loanRemaining_data.push(loanAmount);
                } else {
                    monthlyPayments_data.push(0);
                    loanValue_data.push(0);
                    loanInterest_data.push(0)
                    loanPrincipal_data.push(0);
                    loanRemaining_data.push(0);
                }
            }
        }

        return {
            ...mortgage,
            monthlyPayments_data,
            loanValue_data,
            loanInterest_data,
            loanPrincipal_data,
            loanRemaining_data
        }
    },

    // calculatePropertyValueOLD: () => {
    //     let propertyValue = Number(state().propertyValue);

    //     for (let m = 0; m < state().term; m++) {

    //         let principal = state().loanPrincipal_data[m]
    //         let loanAmountOutstanding = state().loanRemaining_data[m]

    //         state().propertyValue_data.push(propertyValue);

    //         // equity
    //         state().equity_data.push(propertyValue - loanAmountOutstanding);
    //         loanAmountOutstanding -= principal;

    //         let increaseInPropertyValue = propertyValue * state().growthPerMonth[m] / (12 * 100);
    //         state().propertyValueChange_data.push(increaseInPropertyValue);

    //         propertyValue += increaseInPropertyValue;
    //     }

    //     return Calculator;
    // },


    calculatePropertyValue: () => {
        let propertyValue = Number(state().propertyValue);
        const split = state().splitLoan;
        const primary = state().mortgagePrimary;
        const secondary = state().mortgageSecondary;

        let term = state().term

        for (let m = 0; m < term; m++) {

            let principal, loanAmountOutstanding = 0

            // principal
            if (primary.loanPrincipal_data[m]) principal = primary.loanPrincipal_data[m];
            if (split && secondary.loanPrincipal_data[m]) principal += secondary.loanPrincipal_data[m];

            // loan Outstanding
            if (primary.loanRemaining_data[m]) loanAmountOutstanding = primary.loanRemaining_data[m];
            if (split && secondary.loanRemaining_data[m]) loanAmountOutstanding += secondary.loanRemaining_data[m];

            // propertyValue
            state().propertyValue_data.push(propertyValue);

            // equity
            let equity = propertyValue - loanAmountOutstanding
            state().equity_data.push(equity);

            loanAmountOutstanding -= principal;

            let increaseInPropertyValue = propertyValue * state().growthPerMonth[m] / (12 * 100);
            state().propertyValueChange_data.push(increaseInPropertyValue);

            propertyValue += increaseInPropertyValue;
        }

        return Calculator;
    },



    calculateCosts: () => {
        const { duties } = data;
        if (!duties) {

            return Calculator;
        }

        const propertyValue = state().propertyValue;
        const stateTerritory = state().stateTerritory;

        const transactionValue = state().buildingType === VACANT_LAND ? state().landValue : propertyValue


        // Find data specific to the state or Terretory
        if (!stateTerritory) {
            return Calculator;
        }
        const dutiesData = state().statutoryFees ? duties.filter((item) => item.state.toUpperCase() === stateTerritory.toUpperCase()).shift() : undefined
        const fees = dutiesData ? dutiesData.fees : undefined;
        const concessions = dutiesData ? dutiesData.concessions : undefined;

        // fees
        if (fees) {
            fees.forEach(fee => {
                if (matchConditions(state(), fee)) {
                    let value = calculateBracket(transactionValue, fee, state().fees)
                    if (value > 0) state().fees.push({ name: fee.name, value })
                }
            })
        }

        // concessions
        if (concessions) {
            concessions.forEach(concession => {
                if (matchConditions(state(), concession)) {
                    let v = concession.landValue ? transactionValue : propertyValue;
                    let value = calculateBracket(v, concession, state().fees)
                    //if (value > 0) state().fees.push({ name: concession.name, value: -1 * value })
                    state().fees.push({ name: concession.name, value: -1 * value })
                }
            })
        }

        const totalFees = state().fees.length > 0 ? state().fees.reduce((total, fee) => Number(total) + Number(fee.value), 0) : 0;

        let additionalCosts = 0;
        state().additionalCosts.forEach(ac => additionalCosts += ac.amount)
        state().totalCosts = totalFees + additionalCosts// + lmiAverage// + conveyancingAverage;

        return Calculator;
    },


    calculateMonthlyRepayment: () => {
        const primary = state().mortgagePrimary;
        const secondary = state().mortgageSecondary;

        // Primary calculations are always made
        let totalPayments = primary.term
        let monthlyInterestRate = Number(primary.interestRate) / (12 * 100);
        let monthlyRepayment = Number(primary.monthlyRepayment);
        let loanAmount = Number(primary.loanAmount);
        let monthly = monthlyRepayment;
        let interestOnly = primary.initialPeriod.data < 0

        if (state().reverserCalculatedPrincipal) {
            // use the pre-calculated monthylRepayment figure
            state().reverserCalculatedPrincipal = false;
            state().monthlyRepayment = monthlyRepayment;
        } else {
            monthly = interestOnly ? monthlyInterestRate * loanAmount : PMT(monthlyInterestRate, totalPayments, -1 * loanAmount);
            //monthly = PMT(monthlyInterestRate, totalPayments, -1 * loanAmount);
            monthly = roundCents(monthly);
            primary.monthlyRepayment = monthly;
            state().monthlyRepayment = monthly;
        }


        if (state().splitLoan) {
            let interestOnly = secondary.initialPeriod.data < 0
            // Secondary calculations are always made
            totalPayments = secondary.term
            monthlyInterestRate = Number(secondary.interestRate) / (12 * 100);
            monthlyRepayment = Number(secondary.monthlyRepayment);
            loanAmount = Number(secondary.loanAmount);
            monthly = monthlyRepayment;
            if (state().reverserCalculatedPrincipal) {
                state().reverserCalculatedPrincipal = false;
                state().monthlyRepayment = primary.monthlyRepayment + secondary.monthlyRepayment;
            } else {
                monthly = interestOnly ? monthlyInterestRate * loanAmount : PMT(monthlyInterestRate, totalPayments, -1 * loanAmount);
                // monthly = PMT(monthlyInterestRate, totalPayments, -1 * loanAmount);
                monthly = roundCents(monthly);
                secondary.monthlyRepayment = monthly;
                state().monthlyRepayment = primary.monthlyRepayment + secondary.monthlyRepayment;
            }
        }

        // // total repayments (total cost of principal plus interest)
        // let totalRepayments = primary.monthlyRepayment * primary.term;
        // if (state().splitLoan) {
        //     totalRepayments += secondary.monthlyRepayment * secondary.term;
        // }
        // state().totalRepayments = totalRepayments;

        // total interest paid
        // let totalInterest = CUMIPMT(Math.abs(primary.monthlyInterestRate), primary.term, -1 * primary.loanAmount);
        // if (primary.monthlyInterestRate < 0) totalInterest *= -1; // negative interest rate (remember that! circa 2021)
        // if (state().splitLoan) {
        //     let secondaryInterest = CUMIPMT(Math.abs(secondary.monthlyInterestRate), secondary.term, -1 * secondary.loanAmount);
        //     if (secondary.monthlyInterestRate < 0) secondaryInterest *= -1; // negative interest rate (remember that! circa 2021)
        //     totalInterest += secondaryInterest
        // }
        // state().totalInterest = totalInterest;

        // let interest2Principal = totalInterest / totalRepayments;
        // interest2Principal = roundNumber(interest2Principal, 3);
        // interest2Principal *= 1000;
        // interest2Principal /= 10;
        // state().interest2Principal = interest2Principal

        return Calculator;
    },



    calculateExtraPayments: () => {
        state().extraPaymentsRemaining_data = [...state().monthlyPayments_data]

        // new loan balance
        const term = Number(state().term);
        const loanAmount = Number(state().loanAmount)

        let remainingLoan = loanAmount
        let interestValue = 0;
        let principleValue = 0;
        let paymentSavings = 0; // savings in cost of serviceing loan
        let durationSaving = 0; // reduction in loan duration
        let END_OF_LOAN = false // flag

        let loanBalance = []  // remaining loan balance
        let principlePayments = [] // Principle
        let interestPayments = [] // interest
        let extraPayments = []
        let extraMonthlyRepayment = 0

        // calculate until the loanBalabce has reached zero
        let monthIndex = 0;
        const loanCount = state().splitLoan ? 2 : 1 /// HORROR - Must refactor!!!!

        // iterate over all the monthy payments of the non-offset loan

        // ----------- ADDITIONAL PAYMENTS LEDGER ----------
        const additionalPaymentsLedger = [];
        for (let i = 0; i <= term; i++) additionalPaymentsLedger[i] = new Array(loanCount).fill(0)

        state().additionalPayments.map((payment) => {
            const { amount, to, from, frequency, fullTerm, loanIndex = 0 } = payment;
            let fromMonth = fullTerm ? 0 : from;
            let toMonth = fullTerm ? term : to;
            if( frequency.data === 0){
                // one off payment
                additionalPaymentsLedger[from][loanIndex] = additionalPaymentsLedger[from][loanIndex] + Number(amount)
            }
            for (let monthIndex = fromMonth; monthIndex < toMonth; monthIndex++) {
                // set frequency
                const isPaymentMonth = (monthIndex - fromMonth) % frequency.data === 0
                // pay at the end of the frequency period ( when not monthly). It's just better this way
                if (isPaymentMonth && (frequency.data === 1 || monthIndex > fromMonth)) {
                    additionalPaymentsLedger[monthIndex][loanIndex] = additionalPaymentsLedger[monthIndex][loanIndex] + Number(amount)
                }
            }
            return false;
        })
        state().additionalPaymentsLedger = additionalPaymentsLedger


        // ----------- OFFSET LEDGER ----------
        const offsetAccountLedger = [];
        // opening balance
        let openingBalance = [];
        for (let i = 0; i < loanCount; i++) {
            const amount = state().offsetAccountOpeningBalance[i] ? state().offsetAccountOpeningBalance[i].amount : 0;
            openingBalance[i] = amount
            // offsetAccountLedger[i][0] = openingBalance
        }
        for (let i = 0; i <= term; i++) offsetAccountLedger[i] = [...openingBalance];


        state().offsetAccountTransactions.map((offsetTransaction) => {
            const { amount, to, from, frequency, fullTerm, loanIndex = 0 } = offsetTransaction;
            let fromMonth = fullTerm ? 0 : from;
            let toMonth = fullTerm ? term : to;

            // skip initial month (zero-month on establishment)
            fromMonth++;
            toMonth++;

            if (frequency.data === 0) {
                // one-off payment
                fromMonth = from;
                for (let monthIndex = fromMonth; monthIndex <= term; monthIndex++) {
                    offsetAccountLedger[monthIndex][loanIndex] = Math.max(0, Number(offsetAccountLedger[monthIndex][loanIndex]) + Number(amount))
                }
            } else {
                for (let monthIndex = fromMonth; monthIndex < toMonth; monthIndex++) {
                    // set frequency
                    const isPaymentMonth = (1 + (monthIndex - fromMonth)) % frequency.data === 0
                    // pay at the end of the frequency period ( when not monthly). It's just better this way
                    if (isPaymentMonth && (frequency.data === 1 || monthIndex > fromMonth)) {
                        for (let ongoing_monthIndex = monthIndex; ongoing_monthIndex <= term; ongoing_monthIndex++) {
                            offsetAccountLedger[ongoing_monthIndex][loanIndex] = Math.max(0, Number(offsetAccountLedger[ongoing_monthIndex][loanIndex]) + Number(amount))
                        }
                    }
                }
                return false;
            }
            return false;
        })
        state().offsetAccountLedger = offsetAccountLedger


        // ----------- LUMP SUM LEDGER ----------
        let lumpSumPaymentsLedger = []
        for (let i = 0; i <= term; i++) lumpSumPaymentsLedger[i] = new Array(loanCount).fill(0)

        state().lumpSumPayments.map((lumpSum) => {
            const { amount, from, loanIndex = 0 } = lumpSum;
            lumpSumPaymentsLedger[from][loanIndex] = lumpSumPaymentsLedger[from][loanIndex] + amount
            return false;
        })
        state().lumpSumPaymentsLedger = lumpSumPaymentsLedger





        // ----------- Effect of Extra repayments ----------
        if (state().splitLoan) {

            // --------------- SPLIT LOAN -----------------

            // seperate the duration between the two loans
            durationSaving = new Array(loanCount).fill(0) // [0, 0]

            // run down the specific mortgage
            principleValue = new Array(loanCount).fill(0) //[0, 0] // reduction amount
            interestValue = new Array(loanCount).fill(0) //[0, 0]
            END_OF_LOAN = new Array(loanCount).fill(false) //[false, false] // flag

            // track the remaining loan (pv) to determin the extra payment savings as a monthly figure
            remainingLoan = [state().mortgagePrimary.loanAmount, state().mortgageSecondary.loanAmount]

            for (monthIndex = 0; monthIndex < term; monthIndex++) {
                let primaryMonthlyRepayment = state().mortgagePrimary.monthlyPayments_data[monthIndex];
                let secondaryMonthlyRepayment = state().mortgageSecondary.monthlyPayments_data[monthIndex];

                // prevent the loan going negaive
                if (remainingLoan[0] < 0) remainingLoan[0] = 0
                if (remainingLoan[1] < 0) remainingLoan[1] = 0

                // if (state().offsetPayoutEOL) {
                //     // for each loan
                //     for (let loanIndex = 0; loanIndex < loanCount; loanIndex++) {
                //         let offsetBalance = state().offsetAccountLedger[monthIndex][loanIndex]
                //         if (remainingLoan[loanIndex] <= offsetBalance + extraMonthlyRepayment) {
                //             END_OF_LOAN[loanIndex] = true;
                //             let remainder = offsetBalance - remainingLoan;
                //             // treat it as a an additional payment
                //             state().additionalPaymentsLedger[monthIndex][loanIndex] += remainingLoan;
                //             // clear out remaining ledger
                //             for (let i = monthIndex; i < state().offsetAccountLedger.length; i++) {
                //                 state().offsetAccountLedger[i][loanIndex] = remainder;
                //             }
                //         }
                //     }
                // }


                //  // Offset Payout?
                //  if (state().offsetPayoutEOL && offsetBalance > 0) {
                //     if (remainingLoan <= offsetBalance + extraMonthlyRepayment) {
                //         END_OF_LOAN = true
                //         // reduce final repayment if required
                //         let remainder = offsetBalance - remainingLoan
                //         extraMonthlyRepayment = Math.min(0, extraMonthlyRepayment - remainder);
                //         // treat it as a an additional payment
                //         state().additionalPaymentsLedger[monthIndex][0] += remainingLoan
                //         state().offsetPayout.amount = remainingLoan
                //         state().offsetPayout.date = monthIndex
                //         //extraMonthlyRepayment += remainingLoan;
                //         // // clear out remaining ledger
                //         for (let i = monthIndex + 1; i < term; i++) {
                //             state().offsetAccountLedger[i][0] = remainder
                //             // state().additionalPaymentsLedger[i][0] = 0;
                //             // state().lumpSumPaymentsLedger[i][0] = 0;
                //         }
                //     }
                // }


                // one of the loans has been paid out
                if ((END_OF_LOAN[0] || END_OF_LOAN[1])) {
                    if (END_OF_LOAN[0]) {
                        paymentSavings += primaryMonthlyRepayment;
                        remainingLoan[0] = 0
                    }
                    if (END_OF_LOAN[1]) {
                        paymentSavings += secondaryMonthlyRepayment;
                        remainingLoan[1] = 0
                    }
                }

                // reset temp values
                extraMonthlyRepayment = new Array(loanCount).fill(0) // [0, 0]
                principleValue = new Array(loanCount).fill(0) // [0, 0]
                interestValue = new Array(loanCount).fill(0) // [0, 0]


                if ((END_OF_LOAN[0] && END_OF_LOAN[1])) {
                    // skip all of the calcs if the loans have both been paid out
                } else {
                    // Additional payments
                    // Effect - increase monthly repayments (reduce principal)
                    extraMonthlyRepayment = [primaryMonthlyRepayment, secondaryMonthlyRepayment];

                    if (END_OF_LOAN[0]) extraMonthlyRepayment[0] = 0;
                    if (END_OF_LOAN[1]) extraMonthlyRepayment[1] = 0;

                    if (state().additionalPaymentsLedger[monthIndex]) {
                        let additionalPayment = state().additionalPaymentsLedger[monthIndex]
                        for (let loanIndex = 0; loanIndex < loanCount; loanIndex++) {
                            if (END_OF_LOAN[loanIndex]) {
                                // set this to zero - no more payments
                                extraMonthlyRepayment[loanIndex] = 0;
                            } else {
                                extraMonthlyRepayment[loanIndex] += additionalPayment[loanIndex]
                            }
                        }
                    }

                    // Lump Sum payments
                    // Effect - reduce the loan remaining (principal)

                    if (state().lumpSumPaymentsLedger[monthIndex]) {

                        let lumpSum = state().lumpSumPaymentsLedger[monthIndex]
                        // for each loan
                        for (let loanIndex = 0; loanIndex < loanCount; loanIndex++) {
                            if (END_OF_LOAN[loanIndex]) {
                                // set this to zero - no more payments
                                remainingLoan[loanIndex] = 0;
                            } else {
                                let remaining = remainingLoan[loanIndex] - lumpSum[loanIndex];
                                remainingLoan[loanIndex] = Math.max(0, remaining)
                            }
                        }
                    }


                    // if (state().offsetPayoutEOL ) {
                    //     // for each loan
                    //     for (let loanIndex = 0; loanIndex < loanCount; loanIndex++) {
                    //         let offsetBalance = state().offsetAccountLedger[monthIndex][loanIndex]
                    //         if (remainingLoan[loanIndex] <= offsetBalance + extraMonthlyRepayment) {
                    //             END_OF_LOAN[loanIndex] = true;
                    //             let remainder = offsetBalance - remainingLoan;
                    //             // treat it as a an additional payment
                    //             state().additionalPaymentsLedger[monthIndex][loanIndex] += remainingLoan;
                    //             // clear out remaining ledger
                    //             for (let i = monthIndex; i < state().offsetAccountLedger.length; i++) {
                    //                 state().offsetAccountLedger[i][loanIndex] = remainder;
                    //             }
                    //         }
                    //     }
                    // }


                    // Offset Payout?
                    if (state().offsetPayoutEOL) {
                        
                        for (let loanIndex = 0; loanIndex < loanCount; loanIndex++) {
                            let offsetBalance = Number(state().offsetAccountLedger[monthIndex][loanIndex])
                            if (!END_OF_LOAN[loanIndex] && offsetBalance > 0 && Number(remainingLoan[loanIndex]) <= Number(offsetBalance) + Number(extraMonthlyRepayment[loanIndex]) ) {
                                // PAY OUT LOAN
                                END_OF_LOAN[loanIndex] = true;
                                // reduce final repayment if required
                                let remainder = remainingLoan[loanIndex] - offsetBalance;
                                let OffsetPayout = remainingLoan[loanIndex] - remainder;
                                let OffsetRemaining = offsetBalance - OffsetPayout;

                                // prevent a negative repayment
                                extraMonthlyRepayment[loanIndex] = Math.max(0, remainder);
                                state().additionalPaymentsLedger[monthIndex][loanIndex] += OffsetPayout
                                state().offsetPayout.amount = OffsetPayout
                                state().offsetPayout.date = monthIndex

                                // Flush out the remainign offset balance
                                for (let i = monthIndex + 1; i < term; i++) {
                                    state().offsetAccountLedger[i][loanIndex] = OffsetRemaining

                                }

                            }
                        }
                    }


                    // if (remainingLoan <= offsetBalance + extraMonthlyRepayment) {
                    //     END_OF_LOAN = true
                    //     // reduce final repayment if required
                    //     let remainder = offsetBalance - remainingLoan
                    //     extraMonthlyRepayment = Math.min(0, extraMonthlyRepayment - remainder);
                    //     // treat it as a an additional payment
                    //     state().additionalPaymentsLedger[monthIndex][0] += remainingLoan
                    //     state().offsetPayout.amount = remainingLoan
                    //     state().offsetPayout.date = monthIndex
                    //     //extraMonthlyRepayment += remainingLoan;
                    //     // // clear out remaining ledger
                    //     for (let i = monthIndex + 1; i < term; i++) {
                    //         state().offsetAccountLedger[i][0] = remainder
                    //         // state().additionalPaymentsLedger[i][0] = 0;
                    //         // state().lumpSumPaymentsLedger[i][0] = 0;
                    //     }
                    // }


                    // Typical interestValue
                    interestValue = [
                        (remainingLoan[0]) * state().mortgagePrimary.interestRatesPerMonth[monthIndex] / (12 * 100),
                        (remainingLoan[1]) * state().mortgageSecondary.interestRatesPerMonth[monthIndex] / (12 * 100),
                    ]

                    // offset account
                    // effect - reduce interest amount and add the saving as a principalvalue offset ( extra repayment )
                    // for each loan...
                    let offset = offsetAccountLedger[monthIndex]
                    let interestRate
                    for (let loanIndex = 0; loanIndex < loanCount; loanIndex++) {

                        if (END_OF_LOAN[loanIndex]) {
                            interestValue[loanIndex] = 0;
                        } else {
                            // Oh the Horror!! Need to refactor so that mortgages are an array
                            if (loanIndex === 0) {
                                interestRate = state().mortgagePrimary.interestRatesPerMonth[monthIndex] / (12 * 100)
                            }
                            if (loanIndex === 1) {
                                interestRate = state().mortgageSecondary.interestRatesPerMonth[monthIndex] / (12 * 100)
                            }
                            let offsetBalance = offset[loanIndex]
                            if (offsetBalance > 0) {
                                interestValue[loanIndex] = (remainingLoan[loanIndex] - offsetBalance) * interestRate
                            }
                        }
                    }

                    interestValue[0] = Math.max(0, interestValue[0]);
                    interestValue[1] = Math.max(0, interestValue[1]);

                    // calculate principle
                    principleValue[0] = extraMonthlyRepayment[0] - interestValue[0];
                    principleValue[1] = extraMonthlyRepayment[1] - interestValue[1];


                    // check final payment
                    if (principleValue[0] >= remainingLoan[0] || principleValue[1] >= remainingLoan[1]) {
                        // END OF LOAN 0
                        if (principleValue[0] >= remainingLoan[0] && !END_OF_LOAN[0]) {
                            END_OF_LOAN[0] = true;
                            extraMonthlyRepayment[0] = Math.min(extraMonthlyRepayment[0], remainingLoan[0])
                            paymentSavings += principleValue[0] - remainingLoan[0];
                            principleValue[0] = remainingLoan[0];
                        }

                        // END OF LOAN 0
                        if (principleValue[1] >= remainingLoan[1] && !END_OF_LOAN[1]) {
                            END_OF_LOAN[1] = true;
                            extraMonthlyRepayment[1] = Math.min(extraMonthlyRepayment[1], remainingLoan[1])
                            paymentSavings += principleValue[1] - remainingLoan[1];
                            principleValue[1] = remainingLoan[1];
                        }
                    }
                }

                extraPayments.push([...extraMonthlyRepayment])
                loanBalance.push([...remainingLoan]);
                principlePayments.push([...principleValue])
                interestPayments.push([...interestValue])

                // update for next iteration
                remainingLoan[0] -= principleValue[0]
                remainingLoan[1] -= principleValue[1]
            }

            // duration savings = how many months of 0 Payments
            durationSaving = new Array(loanCount).fill(0) // [0, 0]

            for (let i = 0; i < term; i++) {
                for (let j = 0; j < loanCount; j++) {
                    let v = extraPayments[i][j];
                    if (v <= 0) durationSaving[j] = durationSaving[j] + 1
                }
            }


        } else {
            // NOT SPLIT LOAN  

            for (monthIndex = 0; monthIndex < term; monthIndex++) {

                // current repayment
                let monthlyRepayment = state().monthlyPayments_data[monthIndex];
                console.log("month:, ", monthIndex, " : " , END_OF_LOAN)

                if (END_OF_LOAN) {
                    remainingLoan = 0;
                    principleValue = 0
                    interestValue = 0
                    paymentSavings += monthlyRepayment;
                    extraMonthlyRepayment = 0;
                } else {

                    // extra repayment
                    extraMonthlyRepayment = monthlyRepayment;
                    if (state().additionalPaymentsLedger[monthIndex][0]) {
                        // increase monthly payment
                        extraMonthlyRepayment += state().additionalPaymentsLedger[monthIndex][0];
                    }

                    // current interest rate
                    let interestRate = state().interestRatesPerMonth[monthIndex] / (12 * 100);
                    let offsetBalance = state().offsetAccountLedger[monthIndex][0]

                    // lump sum - reduce loan remaining
                    if (state().lumpSumPaymentsLedger[monthIndex][0]) {
                        if (state().lumpSumPaymentsLedger[monthIndex][0] > remainingLoan) {
                            state().lumpSumPaymentsLedger[monthIndex][0] = remainingLoan
                            remainingLoan = 0;
                            END_OF_LOAN = true;
                        } else {
                            remainingLoan -= state().lumpSumPaymentsLedger[monthIndex][0]
                        }
                    }


                    // Offset Payout?
                    if (state().offsetPayoutEOL && offsetBalance > 0) {
                        if (remainingLoan <= offsetBalance + extraMonthlyRepayment) {
                            END_OF_LOAN = true
                            // reduce final repayment if required
                            let remainder = remainingLoan - offsetBalance
                            let OffsetPayout = remainingLoan - remainder;
                            let OffsetRemaining = offsetBalance - OffsetPayout;

                            extraMonthlyRepayment = Math.max(0, remainder);
                            // treat it as a an additional payment
                            state().additionalPaymentsLedger[monthIndex][0] += OffsetPayout
                            state().offsetPayout.amount = OffsetPayout
                            state().offsetPayout.date = monthIndex
                            //extraMonthlyRepayment += remainingLoan;
                            // // clear out remaining ledger
                            for (let i = monthIndex + 1; i < term; i++) {
                                state().offsetAccountLedger[i][0] = OffsetRemaining
                                // state().additionalPaymentsLedger[i][0] = 0;
                                // state().lumpSumPaymentsLedger[i][0] = 0;
                            }
                        }
                    }

                    // offset account reduces the interest on the remaining loan less the offset amount. Cannot be below zero
                    // interestReduction

                    if (offsetBalance && offsetBalance > 0) {
                        interestValue = (remainingLoan - offsetBalance) * interestRate
                    } else {
                        // regular interest on remaining loan
                        interestValue = (remainingLoan) * interestRate
                    }
                    // cannot be zero
                    interestValue = Math.max(0, interestValue);

                    // reduce the principal value by repayment - the interest
                    // manyally stepping through the loan repayments
                    principleValue = extraMonthlyRepayment - interestValue;

                    // check final payment
                    if (principleValue >= remainingLoan) {
                        // END OF LOAN
                        END_OF_LOAN = true;
                        // tally up the difference between the new principal and the typical remaining loan
                        paymentSavings += principleValue - remainingLoan;
                        principleValue = remainingLoan;
                        extraMonthlyRepayment = Math.min(extraMonthlyRepayment, remainingLoan)
                    }

                }

                // update data
                loanBalance.push([remainingLoan]);

                principlePayments.push([principleValue])
                interestPayments.push([interestValue])
                extraPayments.push([extraMonthlyRepayment])

                // update for next iteration
                remainingLoan -= principleValue

            }

            // duration savings = how many months of 0 extraMonthly Payments
            durationSaving = 0;
            extraPayments.map((v) => {
                if (sumArray(v) <= 0) durationSaving++
                return true;
            })
        }

        // update model data
        state().extraPaymentsRemaining_data = [...loanBalance] // AKA Opening Balance
        state().extraPaymentsPrinciple_data = [...principlePayments] // principal component of payment
        state().extraPaymentsInterest_data = [...interestPayments] // interest component of payment
        state().extraPayments_data = [...extraPayments] // payments

        state().extraPayments_saving = paymentSavings
        state().extraPayments_duration = durationSaving

        console.log("paymentSavings:", paymentSavings, "  durationSaving:", durationSaving)


        return Calculator;
    },

    calculateOngoingCosts: () => {
        const ongoing = [];
        const term = state().term;
        const costs = state().ongoingCosts;

        for (let monthIndex = 0; monthIndex < term; monthIndex++) {
            let monthlyCost = 0

            for (let i = 0; i < costs.length; i++) {
                const cost = costs[i];
                if (monthIndex % cost.frequency.data === 0) {
                    monthlyCost += cost.amount;
                }
            }
            ongoing.push(monthlyCost)
        }
        state().ongoingCosts_data = [...ongoing]
        return Calculator
    },

    calculateYield: () => {

        const weekToYear = 52;

        let rent = state().rent;
        let grossYield = state().grossYield;
        let netYield = state().netYield;

        const propertyValue = state().propertyValue
        let annualCosts = state().ongoingCostsAnnual + (state().rentalCosts * 12)

        if (rent === undefined) {
            rent = (((netYield / 100) * propertyValue) + annualCosts) / weekToYear
        }

        if (netYield === undefined) {
            netYield = (((rent * weekToYear) - annualCosts) / propertyValue) * 100
        }

        grossYield = ((rent * weekToYear) / propertyValue) * 100
        state().grossYield = grossYield;

        state().netYield = netYield
        state().rent = rent;

        let rent_schedule = state().rent_schedule;
        // sort by date
        rent_schedule.sort((a, b) => new Date(a.date) - new Date(b.date));

        let targetDate;
        let checkIndex = 0;

        const rent_data = []
        const rentYield_cost = []
        const rentYield_data = []

        for (let m = 0; m < state().term; m++) {
            let startDate = new Date(state().startDate)
            targetDate = new Date(startDate.setMonth(startDate.getMonth() + m))

            for (let i = checkIndex; i < rent_schedule.length; i++) {
                let scheduleDate = new Date(rent_schedule[i].date)
                if (targetDate >= scheduleDate) {
                    rent = rent_schedule[i].amount
                    checkIndex = i;
                }
            }

            rent_data.push(rent);
            // yield 
            netYield = (((rent * weekToYear) - annualCosts) / state().propertyValue_data[m]) * 100

            rentYield_cost.push(annualCosts / 12)
            rentYield_data.push(netYield)

        }

        state().rent_data = [...rent_data]
        state().rentYield_cost = [...rentYield_cost]
        state().rentYield_data = [...rentYield_data]

        return Calculator

    }



}
