export const date2str = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export const simplifyMoney = (num, fixed = 0) => {
    if (num === null) { return null; } // terminate early
    if (num === 0) { return '0'; } // terminate early
    fixed = (!fixed || fixed < 0) ? 0 : fixed; // number of decimal places to show
    var b = Number(num).toPrecision(2).split("e"), // get power
        k = b.length === 1 ? 0 : Math.floor(Math.min(b[1].slice(1), 14) / 3), // floor at decimals, ceiling at trillions
        c = k < 1 ? Number(num).toFixed(0 + fixed) : (num / Math.pow(10, k * 3)).toFixed(1 + fixed), // divide by power
        d = c < 0 ? c : Math.abs(c), // enforce -0 is 0
        e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
    return e;
}

export const precision = ( v, p = 1) =>{
    const value = Number.parseFloat(v).toPrecision(p);
    return  value
}

export const csvFormatMoney = (number = 0, decPlaces = 2, decSep = ".", thouSep = ",") => {
    return formatMoney(number, decPlaces, decSep, "")
}
// export const formatMoney = (number = 0, decPlaces = 2, decSep = ".", thouSep = ",") => {

//     let sign = number < 0 ? "-" : "";
//     let value = isNaN(number) ? 0: number;
//     value = Math.abs(Number(Number(value).toFixed(decPlaces)));
//     let integerValue = Math.floor(value);
//     let floatValue = value % 1;
//     floatValue = Math.round(floatValue * Math.pow(10, decPlaces))
//     floatValue = floatValue.toString();
//     if(floatValue === '0' && decPlaces > 1){
//         floatValue += '0';
//     }
//     integerValue = chunkString(integerValue.toString(), 3).join(thouSep);
//     if(integerValue === '0') sign = ''

//     let prettyValue = `${sign}${integerValue}${decPlaces > 0 ? decSep + floatValue : ''}`;
//     return prettyValue
// }



export const formatMoney = (number = 0, decPlaces = 2, decSep = ".", thouSep = ",") => {

	decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces;
	decSep = typeof decSep === "undefined" ? "." : decSep;
	thouSep = typeof thouSep === "undefined" ? "," : thouSep;
	let sign = number < 0 ? "-" : "";
    let value = 0

	// i is dollar part of price
	let i = String(parseInt(value = Math.abs(Number(number) || 0).toFixed(decPlaces)));
	// j is the length of the "dollar" part of the number
	let j = (i.length > 3) ? i.length - 3 : 0;

	let str = sign;
	str += (j ? i.substr(0, j) + thouSep : "")
	str += i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep)
	str += (decPlaces ? decSep + Math.abs(value - i).toFixed(decPlaces).slice(2) : "");

	return str;
}



// const chunkString = (str, len) => {
//     // const size = Math.ceil(str.length/len)
//     const r = []
//     let startIndex = str.length - len;

//     while (startIndex > 0) {
//         r.unshift(str.substr(startIndex, len))
//         startIndex -= len;
//     }
//     r.unshift(str.substr(0, len + startIndex))

//     return r
// }

export const formatMoneyLabel = (label) => {
    // if it starts with a '-'
    const labelParts = label.split("-");
    if( labelParts.length > 1){
        return `-$${labelParts.join("")}`
    }
    return `$${labelParts.join("")}`
}


// Value must be normalized between -1 and 1
// range must represent a linear range
export const convertForecast = (range, value) => {

    const a = range[0];
    const b = range[1];
    const c = range[2];

    // const neg = value < 0;
    // let cubicValue = (Math.pow(Math.abs(value), 1.0));
    // if (neg) cubicValue *= -1;

    // let forecast = 0
    // if (neg < 0) {
    //     forecast =  (b - a) * cubicValue + b;
    // }else{
    //     forecast =  (c - b) * cubicValue + b;
    // }

    // console.log("Forecast:: ", range, " : ", value, " -> ", forecast)
    // return forecast;

    /// LINEAR
    const neg = value < 0;
    let k = b;
    let m = 0;
    if( neg ){
        m = k - a;
    }else{
        m = c - k
    }
    const forecast = m*value + k;
    return forecast;
}

// Value must be normalized between -1 and 1
// range must represent a linear range
export const normalize = (min, max, value) => {
    /// LINEAR
    let n = (value - min)/ (max - min);
    n = Math.min(1, Math.max(0, n));
    return n;
}

export const roundNumber = (val, dp) => {
    const dpPower = Math.pow(10, dp);
    return Math.round(val * dpPower) / dpPower;
}

export const roundCents = (val) => {
    return roundNumber(val, 2);
}


export const bezierInterpolation = (t, p1, c1, c2, p2) => {

    const t2 = t * t;
    const t3 = t2 * t;

    let p = 0;
    p += p1 + (-p1 * 3 + t * (3 * p1 - p1 * t)) * t;
    p += (3 * c1 + t * (-6 * c1 + c1 * 3 * t)) * t;
    p += (c2 * 3 - c2 * 3 * t) * t2
    p += p2 * t3;

    return p
}


export const createBezier = (A, B, min, max, curvatureA, curvatureB) => {

    let curvature_a = curvatureA ? curvatureA : 0.3;
    let curvature_b = curvatureB ? curvatureB : 0.3;

    // linear scale equation ( normalise )
    let m = 1 / (min - max);
    let c = -1 * max * m;

    // target positions
    let start = { x: 0, y: 0 }
    let end = { x: 1, y: 0 }

    // converted positons
    start.y = m * A + c;
    end.y = m * B + c;

    let startControl = { x: start.x + curvature_a, y: start.y }
    let endControl = { x: end.x - curvature_b, y: end.y }

    return { p1: start, c1: startControl, c2: endControl, p2: end }
}

export const solveLinear = (x1, x2, y1, y2) => {

    // y1 = a.x1 + b
    // y2 = a.x2 + b
    let a = (y1 - y2)/(x1 - x2);
    let b = y1 - a*x1;
    return [a, b];

}

export const transformLinear = (value, matrix) => {
    if(matrix.length === 2) return (value*matrix[0]) + matrix[1];
    return 0;
}


export const transformInverseLinear = (value, matrix) => {
    if(matrix.length === 2) {
        return (value - matrix[1])/matrix[0]

    }
    return 0;
}



export const combineInterestRates = (rates) => {
    let v = 1;
    for( let i = 0; i< rates.length; i++){
        v += v*(rates[i]/100);
    }
    return (v - 1)*100
}


// Number of months between two dates
export const monthDiff = (d1, d2) =>{
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

// export const reduce = (arr) => {
//     let sum = 0;
//     if(Array.isArray(arr)){
//         // add all the elements of the array
//         for( let i = 0; i< arr.length; i++) sum += Number(arr[i])
//     }else{
//         sum = Number(arr);
//     }
//     return sum;
// }

export const sumArray = (arr) => {
    let sum = 0;
    if(Array.isArray(arr)){
        // add all the elements of the array
        for( let i = 0; i< arr.length; i++) sum += Number(arr[i])
    }else{
        sum = Number(arr);
    }
    return sum;
}