import { date2str } from "utils/utils"

export const HIDE_INPUT_CHART = true;

export const RATE_SD = 10;
export const GROWTH_SD = 20;

// Current market constants
// https://moneysmart.gov.au/home-loans/mortgage-calculator
export const NOMINAL_MORTGAGE_RATE = 6.0;
export const NOMINAL_MORTGAGE_RATE_INTEREST_ONLY = 6.5; // not used right now

export const NOMINAL_PROPERTY_GROWTH = 4.0;
export const NOMINAL_TERM = 25;

export const GROWTH_RANGE = [NOMINAL_PROPERTY_GROWTH - GROWTH_SD, NOMINAL_PROPERTY_GROWTH + GROWTH_SD];
// export const INTEREST_RATES_RANGE = [NOMINAL_MORTGAGE_RATE + RATE_SD, -1];
export const INTEREST_RATES_RANGE = [-1, NOMINAL_MORTGAGE_RATE + RATE_SD, ];

export const LENDERS_MORTGAGE_INSURANCE_THRESHOLD = 80;

export const DEFAULT_INTEREST_TREND = [
    [new Date().getFullYear() + NOMINAL_TERM * 0.33, NOMINAL_MORTGAGE_RATE],
    [new Date().getFullYear() + NOMINAL_TERM * 0.67, NOMINAL_MORTGAGE_RATE]
]; // inbetween rate that determines the trend control point [ year, rate ]


export const DEFAULT_ADDITIONAL_COSTS = [{
    description:"Conveyancing", amount:2500,
}
]

export const LMIOptions = [
    "Owner",
    "Investor",
    "Single Mother",
]



export const STATE_ACT = "ACT"
export const STATE_NSW = "NSW"
export const STATE_NT = "NT"
export const STATE_QLD = "QLD"
export const STATE_SA = "SA"
export const STATE_TAS = "TAS"
export const STATE_WA = "WA"
export const STATE_VIC = "VIC"
export const STATE_NOT_APPLICABLE = "NOT APPLICABLE"

export const STATES = [
    STATE_ACT,
    STATE_NSW,
    STATE_NT,
    STATE_QLD,
    STATE_SA,
    STATE_TAS,
    STATE_WA,
    STATE_VIC,
    STATE_NOT_APPLICABLE,
]



export const BUILDING_TYPE = "buildingType"
export const EXISTING_BUILDING = "Existing Building"
export const NEW_BUILDING = "New Building"
export const VACANT_LAND = "Vacant Land"

export const FIRST_HOME_OWNER = "firstHomeOwner"
export const PRIMARY_RESIDENCE = "primaryResidence"
export const FIRST_HOME_OWNER_GRANT =  "firstHomeOwnerGrant"
export const FOREIGN_INVESTOR = "foreignInvestor"

export const BUILDING_TYPES = [
    EXISTING_BUILDING,
    NEW_BUILDING,
    VACANT_LAND,
]

export const InitialFixedPeriodOptions = [
    {label:"No fixed period", data:1},
    {label:"6 months", data:6},
    {label:"1 year", data:12},
    {label:"2 years", data:24},
    {label:"3 years", data:36},
    {label:"5 years", data:60},
]



export const LoanVariabilityOptions = [
    {label:"Variable", data:1},
    {label:"6 months Fixed", data:6},
    {label:"1 year Fixed", data:12},
    {label:"2 years Fixed", data:24},
    {label:"3 years Fixed", data:36},
    {label:"5 years Fixed", data:60},
    {label:"Full Term Fixed", data:0},
    {label:"Interest Only", data:-1}, // Interest Only determined by .data < 0
]


export const RefinancePeriodOptions = [
    {label:"Monthly", data:1},
    {label:"Quarterly", data:3},
    {label:"Annually", data:12},
    {label:"2 years", data:24},
    {label:"3 years", data:36},
    {label:"5 years", data:60},
]


export const REPAYMENT_FREQUENCY = [
    {label:"Monthly", factor:1, span:2000, step: 100},
    {label:"Fortnightly", factor:12/26, span: 1000, step: 10},
    {label:"Weekly", factor:12/52, span: 500, step: 1},
]


export const PAYMENT_FREQUENCY = [
    {label:"Monthly", data:1},
    {label:"Quarterly", data:3},
    {label:"Annually", data:12},
    {label:"One-off", data:0},
]

export const OFFSET_RERPAYMENT_TYPES = [
    "Opening",
    "Regular",
    ""

]

export const PropertyCostOptions = [
    {label:"Monthly", data:1, index:0},
    {label:"Quarterly", data:3, index:1},
    {label:"Annually", data:12, index:2},
    {label:"2 years", data:24, index:3},
    {label:"5 years", data:60, index:4},
]

export const OFFSET_ACCOUNT_TRANSACTION_DEFAULT = {amount:0, from: 0, to:NOMINAL_TERM*12, fullTerm:true, frequency:PAYMENT_FREQUENCY[0], loanIndex:0}
export const EXTRA_PAYMENT_DEFAULT = {amount:0, from: 0, to:NOMINAL_TERM*12, fullTerm:true, frequency:PAYMENT_FREQUENCY[0], loanIndex:0}
export const LUMP_SUM_PAYMENT_DEFAULT = {amount:0, from: 0, frequency:PAYMENT_FREQUENCY[3], loanIndex:0}

export const InitialState = {
    VERSION :1,
    resetFlag: false,
    // STATE PROPERTIES
    newMortgage: true,
    propertyValue: 1000000,
    loanAmount: 800000,

    startDate: date2str( new Date()),
    repaymentFrequency:REPAYMENT_FREQUENCY[0].label,

    interestRate: NOMINAL_MORTGAGE_RATE,
    interestRateTrend: [...DEFAULT_INTEREST_TREND],

    lvrTarget:80,
    lvrTargetDate:new Date(),
    lvrTargetMonth:0,

    //     [new Date().getFullYear() + NOMINAL_TERM * 0.33, NOMINAL_MORTGAGE_RATE],
    //     [new Date().getFullYear() + NOMINAL_TERM * 0.67, NOMINAL_MORTGAGE_RATE]
    // ], // inbetween rate that determines the trend control point [ year, rate ]
    interestRateForecast: NOMINAL_MORTGAGE_RATE, 
    customInterestRates: false,
    interestRateForecastValue:0,
  
    propertyGrowth: NOMINAL_PROPERTY_GROWTH,
    propertyGrowthTrend: [
        [new Date().getFullYear() + NOMINAL_TERM * 0.33, NOMINAL_PROPERTY_GROWTH],
        [new Date().getFullYear() + NOMINAL_TERM * 0.67, NOMINAL_PROPERTY_GROWTH]
    ], // inbetween rate that determines the trend control point [ year, rate ]
    
    propertyGrowthForecast: NOMINAL_PROPERTY_GROWTH,
    propertyGrowthForecastValue:0,
    customPropertyGrowth: false,

    refinancePeriod: RefinancePeriodOptions[1], // to be fixed ( ensure this is forced to be overridden when loanding cache)

    splitLoan: false,
    splitLoanRatio:0.5,

    //TODO: combine in to the mortgage property
    loanLabels: ["Loan 1", "Loan 2"],

    // TODO: use generic mortgage object and use an array of mortgage objects
    // Currently, UI designed to only have two loans max
    mortgagePrimary:
        {
            monthlyRepayment:0, // calculated
            loanAmount:0, // calculated: ratio * loanAmount
            ratio:1.0, // calculated: first is splitLoanRatio, second is 1 - splitLoanRatio ( calculated)
            interestRate:NOMINAL_MORTGAGE_RATE, // default to nominal value
            term:NOMINAL_TERM*12, //months
            initialPeriod:LoanVariabilityOptions[0],

            monthlyPayments_data:[],
            loanPrincipal_data:[],
            loanValue_data:[],
            loanInterest_data:[],
            loanRemaining_data:[],

            interestRatesPerMonth:[]
        },
    mortgageSecondary:
        {   
            monthlyRepayment:0, // calculated
            loanAmount:0, // calculated: ratio * loanAmount
            ratio:0.0, // calculated: first is splitLoanRatio, second is 1 - splitLoanRatio ( calculated)
            interestRate:NOMINAL_MORTGAGE_RATE, // default to nominal value
            term:NOMINAL_TERM*12, // months
            initialPeriod:LoanVariabilityOptions[0],

            monthlyPayments_data:[],
            loanPrincipal_data:[],
            loanValue_data:[],
            loanInterest_data:[],
            loanRemaining_data:[],

            interestRatesPerMonth:[]
        },

    term: NOMINAL_TERM*12, // total months
    termYears: NOMINAL_TERM,
    termMonths: 0,

    forecast: 0.0,

    LMIOption: 0,

    // options
    statutoryFees: true,
    landTax:false,
    primaryResidence: true,
    firstHomeOwner: false,
    firstHomeOwnerGrant:true,
    metropolitanProperty: false,
    stampDutyExemption: false,
    foreignInvestor: false,
    landValue:0, // used only for stamp duty on land value

    stateTerritory: STATES[1],
    buildingType: BUILDING_TYPE[0],

    additionalCosts: DEFAULT_ADDITIONAL_COSTS,
    ongoingCosts: [],
    ongoingCostsAnnual: 0,
    ongoingCostsMonthly: 0,
    ongoingCosts_data:[],

    // Offset
    offsetAccountOpeningBalance: [{amount:0}, {amount:0}],
    offsetAccountTransactions: [],
    offsetAcountLedger: [[]],
    offsetPayoutEOL: false, // payout loan from offset at end of loan
    offsetPayout:{amount:0, date:0},

    // extra repayments
    additionalPayments: [EXTRA_PAYMENT_DEFAULT],
    additionalPaymentsLedger: [[]], // monthy schedule of lump sum payments  [[loan0, loan1], [loan0, loan1] ... term_months ]

    // lump sums
    lumpSumPayments: [LUMP_SUM_PAYMENT_DEFAULT],
    lumpSumPaymentsLedger: [[]], // monthy scedule of payments

    // COMPUTED PROPERTIES
    deposit: 0,
    totalCosts: 0,
    lvr: 0,
    lmi: [],
    lpr:[],
    conveyancing: [],
    stampduty: 0,
    fees: [],
    feesAnnual: [],
    repayment:0,
    monthlyRepayment: 0,
    totalRepayments: 0,
    totalInterest: 0,
    interest2Principal: 0,

    // calculates rates over the term of the loan (cubic bezier)
    growthPerMonth: [],
    growthPerYear: [],
    interestRatesPerYear: [],

    // Combined Monthly data sets
    loanValue_data: [],
    loanRemaining_data:[],
    loanPrincipal_data: [],
    loanInterest_data: [],
    propertyValue_data: [],
    propertyValueChange_data: [],
    monthlyPayments_data: [],
    equity_data: [],


    // Offset calculations
    extraPaymentsRemaining_data:[],
    extraPaymentsPrinciple_data:[],
    extraPaymentsInterest_data:[],
    extraPayments_data:[],

    extraPayments_saving: 0,
    extraPayments_duration: 0,

    reverserCalculatedPrincipal: false,


    rent: undefined,
    rentalCosts: 0,
    rentYield: 5,
    grossYield:5,
    netYield:5,
    rent_data:[], // rent
    rentYield_cost:[], // yield costs
    rentYield_data:[], // yield value
    rent_schedule:[],

    investmentCost: 0,
    renovationCosts:0,


}



